import clsx from 'clsx';
import { useRouter } from 'next/router';

import Image from 'components/common/Image';
import ButtonComponent from 'components/common/Button';
import BasicLinkComponent from 'components/common/Link';
import Markdown from 'components/common/Markdown';
import ResponsiveImage from 'components/common/ResponsiveImage';
import MarkdownWithBullet from 'components/common/MarkdownWithBullet';

import { SliderProps } from 'interfaces/cms/content';
import { rem } from 'lib';
import GTMHelper from 'lib/GTMHelper';
import useMediaQuery from 'hooks/useMediaQuery';
import { useCallback } from 'react';
import styles from './Slide.module.scss';

function Slide({
  id,
  titleColor,
  title,
  subtitleColor,
  subtitle,
  align,
  button,
  image,
  contentAlign,
  bulletColor,
  titleAlign,
}: SliderProps) {
  const { asPath, push } = useRouter();
  const [isMobile] = useMediaQuery();
  const getInfoAlign = () => {
    switch (align) {
      case 'left':
        return 'top-[50%] left-0 -translate-y-[50%]';

      case 'right':
        return 'top-[50%] right-0 -translate-y-[50%]';

      default:
        return 'top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]';
    }
  };

  const getAlignItems = () => {
    if (contentAlign === 'left') {
      return 'items-start';
    }

    if (contentAlign === 'right') {
      return 'items-end';
    }

    return 'items-center';
  };

  const onClickRcashRedirection = useCallback(() => {
    GTMHelper.customRcHomeCategory('Reach Rcash Download Page', {
      eventAction: 'Click from Rcash LP',
    });
    GTMHelper.customEvent('rcash-app-download');

    push(button.buttonUrl);
  }, [GTMHelper]);

  const CTAButton = button && (button.buttonUrl || button.buttonPageSlug) && (
    <>
      <div className="w-full pt-[1.875rem]">
        {asPath === '/personal-loan/r-cash' ? (
          <ButtonComponent
            {...button}
            btnStyle="min-w-full"
            isFullWidth
            onClick={onClickRcashRedirection}
          />
        ) : (
          <BasicLinkComponent
            href={
              !button.buttonPageSlug
                ? button.buttonUrl
                : button.buttonPageSlug.slug
            }
            rel={button.buttonLinkRel}
            isExternalLink={!button.buttonPageSlug}
            passHref
          >
            <ButtonComponent {...button} btnStyle="min-w-full" isFullWidth />
          </BasicLinkComponent>
        )}
      </div>
      {button.disclaimer && (
        <div className="w-full flex items-start justify-center pt-[0.625rem]">
          <Markdown
            md={button.disclaimer.title}
            textColor={button.disclaimer.color}
            textAlign="center"
            hasLockIcon={button.disclaimer?.lockIcon}
          />
        </div>
      )}
    </>
  );

  return (
    <div key={id} className={clsx(styles.slide, 'max-w-[90rem] my-0 mx-auto')}>
      {isMobile ? (
        <div className="w-full overflow-hidden">
          <div className="flex flex-col items-center justify-center p-[2.5rem] min-h-[18.125rem]">
            <div
              className={clsx(
                getAlignItems(),
                'flex flex-col items-center w-full'
              )}
            >
              <div
                className={clsx(styles.slide__title, 'pb-[0.625rem] w-full')}
              >
                <Markdown
                  md={title}
                  textAlign={titleAlign}
                  textColor={titleColor}
                />
              </div>
              <div className={styles.slide__subtitle}>
                <MarkdownWithBullet
                  bulletColor={bulletColor}
                  subtitle={subtitle}
                  align={contentAlign}
                  subtitleColor={subtitleColor}
                />
              </div>
            </div>

            {CTAButton}
          </div>

          {image?.desktopImage && (
            <div className="w-full overflow-hidden">
              <ResponsiveImage
                src={image.desktopImage}
                srcMobile={image.mobileImage}
                artDirection={image.artDirection}
                width="100%"
                height="100%"
              />
            </div>
          )}
        </div>
      ) : (
        <div className="relative overflow-hidden z-0">
          <div
            className={clsx(
              'p-[1.25rem] lg:p-[2.5rem] max-w-[20.3125rem] lg:max-w-[26.5625rem] mx-[1.875rem]',
              'bg-white shadow-[0_1.875rem_0.625rem_#1066cb1a] rounded-2xl',
              'overflow-hidden absolute z-[1] flex flex-col items-center justify-center',
              getInfoAlign()
            )}
          >
            <div
              className={clsx(
                'flex flex-col w-full max-h-[12.0625rem] lg:max-h-[12.5rem] overflow-hidden',
                getAlignItems()
              )}
            >
              <div
                className={clsx(
                  styles.slide__title,
                  'w-full pt-[0.1875rem] pb-[0.625rem]'
                )}
              >
                <Markdown
                  md={title}
                  textAlign={titleAlign}
                  textColor={titleColor}
                  fontSize={rem(16)}
                />
              </div>

              <div className={styles.slide__subtitle}>
                <MarkdownWithBullet
                  bulletColor={bulletColor}
                  subtitle={subtitle}
                  align={contentAlign}
                  subtitleColor={subtitleColor}
                />
              </div>
            </div>

            {CTAButton}
          </div>
          {image?.desktopImage && isMobile === false && (
            <div
              className={clsx(
                'w-full overflow-hidden h-[30rem] relative',
                styles.slide__desktopImage
              )}
            >
              <Image
                src={image?.desktopImage?.url}
                alt={image?.desktopImage?.alt}
                priority={true}
                fill
                style={{
                  objectFit: 'contain',
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default Slide;
