import clsx from 'clsx';

import Slider from 'components/common/Swiper';
import Slide from 'components/DynamicContent/SliderWithCta/Slide';

import { SliderWithCtaProps } from 'interfaces/cms/content';
import { isAsiadirect, rem } from 'lib';

function SliderWithCta({ slider }: SliderWithCtaProps) {
  return (
    <section
      className={clsx(
        'slider_with_cta w-full mx-0 my-auto text-white bg-white p-0 z-0',
        isAsiadirect() && 'asiadirect-swiper'
      )}
    >
      {slider?.length > 1 ? (
        <Slider
          slidesPerView={1}
          navigation
          pagination
          styles={`
          .swiper-container .swiper-wrapper .swiper-slide:last-child {
            margin-right: 0;
          }
        
          .swiper-pagination {
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        
          .swiper-button-prev,
          .swiper-button-next {
            margin-top: ${rem(-30)};
            width: ${rem(40)};
            height: ${rem(40)};
            color: #005098;
          }
        
          .swiper-button-prev {
            left: ${rem(10)};
          }
        
          .swiper-button-next {
            right: ${rem(10)};
          }

          .swiper-button-prev svg,
          .swiper-button-next svg {
            width: ${rem(20)};
            height: ${rem(20)};
          }
        
          .swiper-button-prev,
          .swiper-button-next {
            font-size: ${rem(18)};
            font-weight: bold;
            background-color: #fff;
            box-shadow: 0 ${rem(3)} ${rem(6)} #0000001a;
            border: ${rem(1)} solid #f4f8fe;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        
          .swiper-pagination-bullet {
            margin: 0 ${rem(4)};
            width: ${rem(15)};
            height: ${rem(13)};
            background: url('https://rabbit-care-images.imgix.net/heart_44c1869696/heart_44c1869696.svg');
            background-repeat: no-repeat;
          }

          @media only screen and (max-width: 768px) {
            .swiper-button-prev,
            .swiper-button-next {
              display: none;
            }
          }
        `}
        >
          {slider.map((slide) => (
            <div
              className="mobile-bg"
              key={slide.id}
              style={{ backgroundColor: slide?.backgroundColor }}
            >
              <Slide {...slide} />
            </div>
          ))}
        </Slider>
      ) : (
        slider?.map((slide) => (
          <div
            className="mobile-bg"
            key={slide.id}
            style={{ backgroundColor: slide?.backgroundColor }}
          >
            <Slide {...slide} />
          </div>
        ))
      )}
    </section>
  );
}

export default SliderWithCta;
